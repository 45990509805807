<template>
	<div>
		<!-- 子账号管理 -->
		<div class="queryBox mb20">
			<el-button size="mini" type="primary" icon="el-icon-plu" @click="addAccount('new')">新 增</el-button>
		</div>
		<template>
			<table class="dataTable" v-loading="loading">
				<thead>
					<tr>
						<th class="textCenter">序号</th>
						<th class="textCenter">登录账号</th>
						<th class="textCenter">账号状态</th>
						<th class="textCenter">代付状态</th>
						<th class="textCenter">设置公钥</th>
						<th class="textCenter">功能</th>
						<th class="textCenter">备注</th>
					</tr>
				</thead>
				<tbody>

					<tr class="" v-for="(item,index) in tableData" v-if="tableData && tableData.length > 0" :key="index">
						<td class="textCenter">{{index+1}}</td>
						<td class="textCenter">{{item.username}}</td>
						<td class="textCenter "> <span :class="item.status==0?'csuccess':'cdanger'">{{item.status===0?'正常':'停用'}}</span></td>
						<td class="textCenter "> <span :class="item.status_pay==0?'csuccess':'cdanger'">{{item.status_pay===0?'启用':'停用'}}</span></td>
						<td class="textCenter "> <span :class="item.status_key==0?'csuccess':'cdanger'">{{item.status_key===0?'是':'否'}}</span></td>
						<td class="">
							<el-link type="primary" @click="resPassword(item)" class="mr20">重置登录密码</el-link>
							<el-link type="primary" @click="setMemberGoogleKey(item)" class="mr20">重置谷歌验证码</el-link>
							<el-link type="primary" @click="addAccount('edt',item)">编辑</el-link>
						</td>
						<td class="textCenter">{{item.name}}</td>
					</tr>
					<tr class="nodata" v-if="!tableData || tableData.length === 0">
						<td colspan="10"> 暂无数据</td>
					</tr>
				</tbody>
			</table>
		</template>
		<div class="flexX  mt20">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="page" :page-sizes="pageSizesArr" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
			 :total="total"></el-pagination>
		</div>
		<el-dialog center class="dialog" top="150px" :title="title" :visible.sync="dialogVisible" @close="close"
		 :close-on-click-modal="false">
			<div class="formBox" v-loading="dialogloading">
				<div>
					<div v-if="titleName" class="mb20">{{titleName}}</div>
					<el-form :label-position="labelPosition" label-width="100px" v-if="type === 'new'|| type === 'edt'">
						
						<el-form-item label="登录账号">
							<el-input :disabled="type === 'payPassword'||type === 'edt'" v-model="trader_username" placeholder="请输入登录账号"></el-input>
						</el-form-item>
						
						<el-form-item label="登录密码"  v-if="type === 'new'">
							<el-input autocomplete="new-password" type="password" v-model="password" placeholder="请输入登录密码"></el-input>
						</el-form-item>
						<el-form-item label="确认登录密码"  v-if="type === 'new'">
							<el-input autocomplete="new-password" type="password" v-model="passwordSure" placeholder="请再次输入登录密码"></el-input>
						</el-form-item>
						<el-form-item class="mt20" label="登录白名单">
							<!-- <div class="flexX"> -->
							<el-input type="textarea" v-model="login_whitelist" placeholder="请输入登录白名单"></el-input>
							<span class="cdanger">白名单多个IP请用英文状态下的","分割</span>
							<!-- </div> -->
						</el-form-item>
						<el-form-item label="备注">
							<el-input v-model="note" placeholder="请输入备注"></el-input>
						</el-form-item>
						<el-form-item label="账号状态">
							<el-switch v-model="status" class="mr20"></el-switch>
							<span>{{status?'正常':'停用'}}</span>
						</el-form-item>
						
						<el-form-item label="代付状态">
							<el-switch v-model="status_pay" class="mr20"></el-switch>
							<span>{{status_pay?'启用':'停用'}}</span>
						</el-form-item>
						
						<el-form-item label="设置公钥">
							<el-switch v-model="status_key" class="mr20"></el-switch>
							<span>{{status_key?'是':'否'}}</span>
						</el-form-item>
						
					</el-form>
					
				</div>
				<div class="textCenter">
					<el-button type="primary" @click="onSubmit">{{submitText}}</el-button>
				</div>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isIncludeHanzi
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				loading: false,
				dialogloading: false,
				dialogVisible: false,
				date: [],
				input: '',
				tableData: [],
				// dataTotal: {},
				pageSizesArr: [20, 50, 100, 200],
				pageSize: 20,
				total: 0,
				page: 1,
			
				//新增
				labelPosition: 'right',	
				trader_username:'',//账号	
				password:'',
				passwordSure: '', //确认密码
				login_whitelist:'',//登录白名单
				status: true,	//账号状态 0 true  1 false
				status_pay:false,//实时代付状态 0 true  1 false
				status_key:false,//是否可以设置主账号公钥 0 true  1 false
				note:'',//备注
				id: '',//修改
				type: '', //new  新增   edt修改
				title: '',
				titleName: '',
				submitText: '',
				item:{}
			}
		},
		created() {
			this.subList()
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					return ((Math.floor((value * 1)*100)/100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			
			subList() {
				// this.loading = true
				this.$api.subList({
					page: this.page,
					size: this.pageSize
				}).then(res => {
					this.loading = false
					if (res.status === 1) {
						this.tableData = res.data.data
						this.total = res.data.total
						
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
					// console.log(res)
				}).catch(error => {
					this.loading = false
					// this.$message({
					// 	type: 'error',
					// 	message: error
					// })
				})
			},
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val
				this.page = 1
				this.subList()
			},
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.page = val
				this.subList()
			},
			addAccount(type, item) {
				this.dialogVisible = true
				this.type = type
				if (item) {
					this.item = item
					this.trader_username=this.item.username; //账号
					this.login_whitelist=this.item.login_whitelist; //登录白名单
					this.status= this.item.status==0?true:false; 	//账号状态 0 true  1 false
					this.status_pay=this.item.status_pay==0?true:false; //实时代付状态 0 true  1 false
					this.status_key=this.item.status_key==0?true:false; //是否可以设置主账号公钥 0 true  1 false
					this.note=this.item.name; //备注
					this.id= this.item.id; //修改this.
				}
				if (this.type === 'new') {
					this.title = '新增子账号'
					this.submitText = '新 增'

				} else if (this.type === 'edt') {
					this.title = '编辑子账号'
					this.submitText = '修 改'
				}
			},
			close() {
				this.dialogVisible = false
				this.item = {};
				this.trader_username='';
				this.password='';
				this.passwordSure='';
				this.login_whitelist='';
				this.note='';
				this.status = true;
				this.status_key = false;
				this.status_pay = false;
			},
			//新增
			onSubmit() {
				if (this.type === 'new') {
					//新增商家
					if (isEmpty(this.trader_username, '请输入登录账号')) {
						return
					}
					if (isIncludeHanzi(this.trader_username, '登录账号不能包含汉字')) {
						return
					}
					if (isEmpty(this.password, '请输入商家密码')) {
						return
					}
					if (this.password != this.passwordSure) {
						this.$message({
							type: 'error',
							message: '两次输入密码不一致'
						})
						return
					}
					let data = {
						trader_username: this.trader_username,
						password: this.$md5(this.password),
						status: this.status ? 0 : 1,
						status_pay: this.status_pay ? 0 : 1,
						status_key: this.status_key ? 0 : 1,
						note:this.note,
						login_whitelist: this.login_whitelist,
					}
					this.dialogloading = true
					this.$api.addSub(data).then(res => {
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							});
							this.close();
							this.page = 1;
							this.subList()

						} else {
							this.$message({
								type: 'error',
								message: res.msg
							});
						}
					}).catch(error => {
						this.dialogloading = false
					})
				} 
				else if (this.type === 'edt') {
					//编辑
					let data = {
						id: this.id,
						note:this.note,
						status: this.status ? 0 : 1,
						status_key: this.status_key ? 0 : 1,
						status_pay: this.status_pay ? 0 : 1,
						login_whitelist: this.login_whitelist,
					}
					this.dialogloading = true
					this.$api.editSub(data).then(res => {
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							});
							this.close();
							this.subList()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							});
						}
						// console.log(res)
					}).catch(error => {
						this.dialogloading = false
					})
				} else if (this.type === 'payPassword') {
					this.$confirm(`确认设置代付密码吗?`, `提示`, {
						confirmButtonText: `确认`,
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						if (isEmpty(this.form.password, '代付密码不能为空')) {
							return
						}
						if (this.form.password != this.passwordSure) {
							this.$message({
								type: 'error',
								message: '两次输入密码不一致'
							})
							return
						}
						let data = {
							id: this.id,
							business_no: this.business_no,
							password: this.$md5(this.form.password),
						}
						this.dialogloading = true
						this.$api.setTraderPayPassword(data).then(res => {
							this.dialogloading = false
							if (res.status === 1) {
								this.$message({
									type: 'success',
									message: '代付密码设置成功'
								})
								this.close()
							} else {
								this.$message({
									type: 'error',
									message: res.msg
								})
							}
						}).catch(error => {
							this.dialogloading = false
						})
					}).catch(() => {
						this.$message({
							type: 'info',
							message: `已取消设置代付密码`
						});
					});
				} else if (this.type === 'freeze' || this.type === 'unFreeze') {
					//新增商家
					this.$confirm(`确认${this.type === 'freeze'?'冻结金额':'解冻金额'}?`, `提示`, {
						confirmButtonText: `确认`,
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						if (!isMoney(this.amount, '余额只能是数字，最多输入两位小数')) {
							return
						}
						let data = {
							id: this.id,
							business_no: this.business_no,
							amount: this.amount,
							type: this.type === 'freeze' ? 'OUT' : 'IN'
						}
						this.dialogloading = true
						this.$api.setAmount(data).then(res => {
							this.dialogloading = false
							if (res.status === 1) {
								this.$message({
									type: 'success',
									message: res.msg
								})
								this.traderListtList()
								this.close()
							} else {
								this.$message({
									type: 'error',
									message: res.msg
								})
							}
						}).catch(error => {
							this.dialogloading = false
						})
					}).catch(() => {
						this.$message({
							type: 'info',
							message: `已取消${this.type === 'freeze'?'冻结金额':'解冻金额'}`
						});
					});
				} else if (this.type === 'fee') {
					let errorString = ''
					var pattern = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/

					for (let i = 0; i < this.feeData.length; i++) {
						if (this.feeData[i].fee === '' || (!pattern.test(this.feeData[i].fee) && this.feeData[i].fee != 0 && this.feeData[
								i].fee != '0')) {
							errorString = '手续费只能是数字，最多两位小数'
							break;
						} else if (this.feeData[i].fee < this.feeData[i].agent_fee) {
							errorString = this.feeData[i].name + ': 商家手续费不能低于代理手续费!'
							break;
						}
					}
					if (errorString != '') {
						this.$message({
							type: 'error',
							message: errorString
						})
						return
					}
					let feeData = this.feeData.map(item => {
						return {
							"pay_type": item.pay_type,
							"fee": item.fee
						}
					})
					this.dialogloading = true
					//设置手续费

					let data = {
						trader_id: this.id,
						data: JSON.stringify(feeData)
					}
					// console.log('feedata', data)
					this.$api.setTraderFee(data).then(res => {
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: '手续费设置成功'
							})
							this.close()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false
					})
				} 
				else if (this.type === 'singleLimit') {
					let errorString = ''
					var pattern = /^[0-9]*$/

					for (let i = 0; i < this.singleLimitData.length; i++) {
						if (this.singleLimitData[i].min_gold === '' || !pattern.test(this.singleLimitData[i].min_gold)) {
							errorString = '最小限额只能是数字'
							break;
						} 
						if (Number(this.singleLimitData[i].min_gold)<0) {
							errorString = '最小限额不能小于0'
							break;
						} 
						if (this.singleLimitData[i].max_gold === '' || !pattern.test(this.singleLimitData[i].max_gold)) {
							errorString = '最大限额只能是数字'
							break;
						} 
						if (Number(this.singleLimitData[i].max_gold)<0) {
							errorString = '最大限额不能小于0'
							break;
						} 
						if (Number(this.singleLimitData[i].max_gold)<Number(this.singleLimitData[i].min_gold)) {
							errorString = '最大限额不能小于最小限额'
							break;
						} 
					}
					if (errorString != '') {
						this.$message({
							type: 'error',
							message: errorString
						})
						return
					}
					let singleLimitData = this.singleLimitData.map(item => {
						return {
							"pay_type": item.pay_type,
							"min_gold": item.min_gold,
							"max_gold": item.max_gold
						}
					})
					this.dialogloading = true
					//设置手续费

					let data = {
						trader_id: this.id,
						data: JSON.stringify(singleLimitData)
					}
					// console.log('feedata', data)
					this.$api.setTraderGold(data).then(res => {
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: '单笔限额设置成功'
							})
							this.close()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false
					})
				} 
				else if (this.type === 'payPower') {
					let pay_type={};
					for(let i = 0;i<this.payWay.length;i++){
						let item = this.payWay[i]
						if(item.state){
							let pay_type_list = []
							for(let j = 0;j<item.pay_type_list.length;j++){
								let subitem = item.pay_type_list[j]
								if(subitem.state){
									pay_type_list.push(subitem.id)
								}
							}
							let payObj={}
							pay_type[item.id] = pay_type_list
							 // pay_type.push(payObj)
						}
					}
					// console.log(pay_type)
					// return
					let data = {
						data: JSON.stringify(pay_type) ,
						trader_id: this.id,
						business_no: this.business_no,
					}
					this.dialogloading = true;
					this.$api.setTraderPayWay(data).then(res => {
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: '支付权限配置成功'
							})
							this.close()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false
					})
					// console.log(data)

				}else if (this.type === 'ratio') {
					this.$confirm('确认更新支付方式下各通道的比例吗?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						let ratioList = []
						let errorMsg='';
						for(let i = 0;i<this.ratio.length;i++){
							let item = this.ratio[i];
							if(item.ratio == ''){
								errorMsg = `${item.pay_type_name} - ${item.payway_type_name}的比例不能为空`
								break;
							}
							if(isNaN(item.ratio)){
								errorMsg = `${item.pay_type_name} - ${item.payway_type_name}的比例只能是数字`
								break;
							}
							if(item.ratio<0){
								errorMsg = `${item.pay_type_name} - ${item.payway_type_name}的比例不能小于0`
								break;
							}
						}
						if(errorMsg!=""){
							this.$message({
								type: 'error',
								message: errorMsg
							})
							return
						}
						
						ratioList = this.ratio.map(item=>{
							return {pay_type:item.pay_type,payway_type_id:item.payway_type_id,ratio:item.ratio}
						})
						let data = {
							data: JSON.stringify(ratioList) ,
							trader_id: this.id,
							business_no: this.business_no,
						}
						this.dialogloading = true;
						this.$api.setRatio(data).then(res => {
							this.dialogloading = false
							if (res.status === 1) {
								this.$message({
									type: 'success',
									message: '支付方式下各通道比例设置成功'
								})
								this.close()
							} else {
								this.$message({
									type: 'error',
									message: res.msg
								})
							}
						}).catch(error => {
							this.dialogloading = false
						})
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消更新支付方式下各通道比例'
						});
					});
				}else if(this.type == 'whitelist'){
					let data = {
						id:this.item.id,
						login_ip:this.item.login_whitelist,
						repay_ip:this.item.repay_whitelist
					}
					this.dialogloading = true;
					this.$api.setWhitelist(data).then(res=>{
						this.dialogloading = false
						if(res.status==1){
							this.$message({type:"success",message:"白名单设置成功"})
							this.close()
						}else{
							this.$message({type:"error",message:res.msg})
						}
					}).catch(error=>{
						this.dialogloading = false
					})
				}
			},
			//重置商家密码
			resPassword(item) {
				this.$confirm('确认重置子账号' + item.username + '的密码吗?', '重置密码', {
					confirmButtonText: '重置',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						id: item.id,
					}
					this.loading = true
					this.$api.setSubPassword(data).then(res => {
						// console.log(res)
						this.loading = false
						if (res.status === 1) {
							this.$alert('新密码为:' + res.data, '重置密码成功', {
								confirmButtonText: '确定',
							});
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							});
						}

					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消重置子账号的密码'
					});
				});

			},
			//重置谷歌验证码
			setMemberGoogleKey(item) {
				this.$confirm('确认重置子账号' + item.username + '的谷歌验证码吗?', '重置谷歌验证码', {
					confirmButtonText: '重置',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						id: item.id,
					}
					this.loading = true
					this.$api.setGoogleKey(data).then(res => {
						// console.log(res)
						this.loading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							});
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							});
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消重置子账号的密码'
					});
				});

			},
		
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.formBox {
		width: 80%;
		margin: 0 auto;
	}
</style>
